<template>
  <div>
    <!-- Controllers -->
    <div
      color="white"
      class="d-flex justify-end py-4 pr-6"
    >
      <v-btn color="primary" type="submit" class="mr-3" dark depressed @click.prevent="update()" v-text="$t('common.save')" />
    </div>
    <!-- Body -->
    <v-container class="px-6 px-md-0 px-lg-10 pt-0 pb-6">
      <v-row class="my-0">
        <v-col cols="12" md="8" class="py-0">
          <v-row class="mb-6">
            <v-col id="currency" cols="5">
              <h3 class="my-4" v-text="$t('project.payments.currency')" />
              <v-autocomplete
                v-model="form.pricing.currency"
                :error-messages="getFieldErrors('pricing.currency')"
                :label="$t('project.payments.currency')"
                :items="currencies"
                item-text="currency"
                item-value="code"
                outlined
                dense
                hide-details="auto"
                @blur="$v.form.pricing.currency.$touch()"
              />
            </v-col>
          </v-row>
          <!-- Stripe connected accounts -->
          <v-row class="mb-6">
            <v-col id="accountId" cols="11">
              <h3 class="my-4" v-text="$t('project.payments.stripeTitle')" />
              <v-row style="display: block !important;" class="my-3 px-2">
                <v-icon
                  color="primary"
                  size="24"
                >
                  lightbulb_outline
                </v-icon>
                {{ $t('project.payments.stripeSubtitle') }}
              </v-row>
              <div v-if="stripeAccount" class="my-6">
                <v-row v-if="form.stripe.accountId" class="my-3 px-2 d-flex align-center">
                  <svg class="mr-1" height="22" width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style="border-radius: 10px;"><g fill="none" fill-rule="evenodd"><path fill="#6772E5" d="M0 0h32v32H0z" /><path fill="#FFF" fill-rule="nonzero" d="M14.56 12.33c0-.78.64-1.08 1.7-1.08 1.52 0 3.43.46 4.95 1.28v-4.7c-1.66-.65-3.3-.91-4.95-.91-4.05 0-6.75 2.11-6.75 5.65 0 5.5 7.59 4.63 7.59 7 0 .92-.8 1.22-1.92 1.22-1.65 0-3.77-.68-5.45-1.6v4.75c1.86.8 3.74 1.14 5.45 1.14 4.15 0 7-2.05 7-5.63-.01-5.94-7.62-4.89-7.62-7.12z" /></g></svg>
                  {{ stripeAccount.name }}
                  <v-chip label outlined x-small
                          class="ml-3"
                          :color="getStripeAccountStatusColor(stripeAccount.status)"
                          v-text="$t(`project.payments.accountStatus.${stripeAccount.status}`)"
                  />
                </v-row>
                <v-row v-if="form.stripe.accountId" style="display: block !important;" class="my-3 px-2">
                  <template v-if="stripeAccount.status !== 'complete'">
                    <v-icon
                      color="error"
                      size="24"
                    >
                      cancel
                    </v-icon>
                    {{ $t(`project.payments.accountStatus.${stripeAccount.status}Subtitle`) }}
                  </template>
                  <span v-if="stripeAccount.status === 'incomplete'" class="primary--text"
                        style="cursor: pointer;"
                        @click="() => goToStripe(form.stripe.accountId)"
                  >
                    {{ $t(`project.payments.accountStatus.incompleteSubtitleLink`) }}
                  </span>
                </v-row>
              </div>
              <v-text-field
                ref="stripeAccountField"
                v-model="form.stripe.accountId"
                :error-messages="stripeAccountNotExist ? $t(`validation.stripeAccountExistenceCheck`) : getFieldErrors('stripe.accountId')"
                :placeholder="$t('project.payments.accountId')"
                outlined
                dense
                hide-details="auto"
                :disabled="!isInputEnabled"
                class="lock-button my-3"
                @blur="checkStripeAccount"
              >
                <template #prepend-inner>
                  <v-icon class="ma-0" @click="isInputEnabled = !isInputEnabled">
                    {{ isInputEnabled ? 'lock_open' : 'lock_outline' }}
                  </v-icon>
                </template>
                <template #append>
                  <v-icon v-if="form.stripe.accountId" @click="copyToClipboard(form.stripe.accountId)">
                    content_copy
                  </v-icon>
                </template>
              </v-text-field>
              <v-row
                no-gutters
                style="flex: initial !important;
                        cursor: pointer;"
                class="align-center mt-5"
                @click="createStripeAccount"
              >
                <v-btn icon x-small color="primary">
                  <v-icon v-text="'add'" />
                </v-btn>
                <span
                  class="ml-2 primary--text"
                >
                  {{ $t(`project.payments.addConnectedAccount`) }}
                </span>
              </v-row>
            </v-col>
          </v-row>
          <!-- Proration Payments -->
          <v-row class="mt-9">
            <v-col cols="11">
              <v-row>
                <v-col cols="11">
                  <h2 v-text="$t('project.payments.headerProration')" />
                </v-col>
                <v-col cols="11" class="px-2">
                  <v-icon color="primary" size="24">
                    lightbulb_outline
                  </v-icon>
                  {{ $t('project.payments.headerProrationDisclaimer') }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="11">
              <div class="d-flex align-start">
                <div class="pr-3">
                  <v-switch v-model="isProrationEnabled" @change="$v.isProrationEnabled.$touch()">
                    <template #label>
                      <div>
                        {{ $t('project.payments.prorationOf') }}<strong class="black--text">&nbsp;{{ $t('project.payments.scheduledPayments') }}</strong>
                      </div>
                    </template>
                  </v-switch>
                </div>
                <global-tooltip :text="$t('project.payments.pricingProrationOfscheduledPaymentsTooltip')" />
              </div>
            </v-col>
            <v-col v-if="isProrationEnabled" cols="12">
              <v-row>
                <v-col cols="11" sm="5">
                  <date-field
                    v-model="form.pricing.prorationDate.start"
                    :label="$t('project.payments.pricingProrationDateStart')" :timezone="timezone"
                    :error-messages="getFieldErrors('pricing.prorationDate.start')"
                    @blur="$v.form.pricing.prorationDate.start.$touch()" @resetDate="form.pricing.prorationDate.start = null"
                  />
                </v-col>
                <v-col cols="1" class="pl-0 mt-2">
                  <global-tooltip :text="$t('project.payments.pricingProrationDateStartTooltip')" />
                </v-col>
                <v-col cols="11" sm="5">
                  <date-field
                    v-model="form.pricing.prorationDate.end"
                    :label="$t('project.payments.pricingProrationDateEnd')" :timezone="timezone"
                    :error-messages="getFieldErrors('pricing.prorationDate.end')"
                    @blur="$v.form.pricing.prorationDate.end.$touch()" @resetDate="form.pricing.prorationDate.end = null"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Payments before accept user -->
          <v-row v-if="approvalRequired" class="mt-9">
            <v-col cols="11">
              <v-row>
                <v-col cols="11">
                  <h2 v-text="$t('project.payments.headerPaymentsBefore')" />
                </v-col>
                <v-col cols="11" class="px-2">
                  <v-icon color="primary" size="24">
                    lightbulb_outline
                  </v-icon>
                  {{ $t('project.payments.headerPaymentsBeforeDisclaimer') }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="11" sm="5">
              <v-text-field
                v-model.number="form.pricing.enrollPrice" type="number" min="1" outlined dense
                :label="$t('common.amount')" :error-messages="getFieldErrors('pricing.enrollPrice')"
                @keypress="validateKeyNumeric($event)" @blur="$v.form.pricing.enrollPrice.$touch()"
              />
            </v-col>
          </v-row>
          <!-- Payments list -->
          <v-row class="mt-9">
            <v-col cols="11">
              <h2 v-text="headerPayments" />
            </v-col>
            <v-col cols="11" class="px-2">
              <v-icon color="primary" size="24">
                lightbulb_outline
              </v-icon>
              {{ $t('project.payments.headerPaymentsDisclaimer') }}
            </v-col>
            <v-col v-if="!form.pricing.priceOptions.length" cols="11" class="py-1 px-3">
              <v-btn class="w-auto" icon small color="primary" @click="addNewPriceOption()">
                <v-icon dark class="mx-1">
                  add_box
                </v-icon>
                <span>{{ $t('project.payments.addPayment') }}</span>
              </v-btn>
            </v-col>
            <v-row v-for="(option, index) in form.pricing.priceOptions" :key="index" no-gutters>
              <v-col cols="11" class="d-flex align-center justify-space-between py-1 px-3 mb-4">
                <h3 v-text="`${$t('project.payments.pricingPriceOptionsNameTitle')} ${index + 1}`" />
                <v-btn class="w-auto" icon small color="primary" @click="form.pricing.priceOptions.splice(index, 1)">
                  <span>{{ $t('common.delete') }}</span>
                  <v-icon dark class="mx-1">
                    delete_outline
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col cols="11" class="py-1 px-3">
                <v-alert
                  v-if="isDirtyPriceOption($v.form.pricing.priceOptions.$each[index]) && isEmptyPriceOption(option)"
                  type="error"
                >
                  {{ $t('project.payments.pricingPriceOptionsEmptyError') }}
                </v-alert>
              </v-col>
              <v-col cols="11" class="py-1 px-3">
                <v-text-field
                  v-model="option.name" :label="$t('project.payments.pricingPriceOptionsName')"
                  :error-messages="$v.form.pricing.priceOptions.$each[index].name.$error ? $t('validation.required') : ''"
                  outlined dense @blur="$v.form.pricing.priceOptions.$each[index].name.$touch()"
                />
              </v-col>
              <v-col cols="11" class="d-flex py-1 px-3">
                <h4 class="pr-3" v-text="$t('project.payments.pricingPriceOptionsInstantAmountTitle')" />
                <global-tooltip :text="$t('project.payments.pricingPriceOptionsInstantAmountTitleTooltip')" />
              </v-col>
              <v-col cols="11" sm="5" class="d-flex py-1 px-3">
                <v-text-field
                  v-model.number="option.instant.amount" :label="$t('common.amount')"
                  :error-messages="!$v.form.pricing.priceOptions.$each[index].instant.amount.numeric
                    || ($v.form.pricing.priceOptions.$each[index].instant.amount.$dirty
                      && isEmptyPriceOption(option)) ? $t('validation.numeric') : ''"
                  type="number" min="1" outlined dense @keypress="validateKeyNumericPrice($event)"
                  @blur="$v.form.pricing.priceOptions.$each[index].instant.amount.$touch()"
                />
              </v-col>
              <v-col cols="11" class="d-flex py-1 px-3">
                <h4 class="pr-3" v-text="$t('project.payments.pricingPriceOptionsSubscriptionTitle')" />
                <global-tooltip :text="$t('project.payments.pricingPriceOptionsSubscriptionTitleTooltip')" />
              </v-col>
              <v-col cols="11" sm="5" class="py-1 px-3">
                <v-text-field
                  v-model.number="option.subscription.amount" :label="$t('project.payments.pricingPriceOptionsSubscriptionAmount')"
                  :error-messages="$v.form.pricing.priceOptions.$each[index].subscription.amount.$error ||
                    ($v.form.pricing.priceOptions.$each[index].subscription.amount.$dirty && !option.subscription.amount && !option.instant.amount) ?
                      $t('validation.required') : ''"
                  type="number" min="1" outlined dense
                  @keypress="validateKeyNumericPrice($event)"
                  @blur="$v.form.pricing.priceOptions.$each[index].subscription.amount.$touch()"
                />
              </v-col>
              <v-col cols="1" class="pl-0 mt-3">
                <global-tooltip :text="$t('project.payments.pricingPriceOptionsSubscriptionAmountTooltip')" />
              </v-col>
              <v-col cols="11" sm="5" class="py-1 px-3">
                <date-field
                  v-model="option.subscription.startBillingDate"
                  :error-messages="$v.form.pricing.priceOptions.$each[index].subscription.startBillingDate.$error ||
                    ($v.form.pricing.priceOptions.$each[index].subscription.startBillingDate.$dirty
                      && !option.subscription.startBillingDate && !option.instant.amount)
                    ? $t('validation.required') : ''"
                  :label="$t('project.payments.pricingPriceOptionsSubscriptionstartBillingDate')"
                  :timezone="timezone"
                  @blur="$v.form.pricing.priceOptions.$each[index].subscription.startBillingDate.$touch()"
                  @resetDate="option.subscription.startBillingDate = null"
                />
              </v-col>
              <v-col cols="11" sm="5" class="py-1 px-3">
                <v-select
                  v-model="option.subscription.installmentCount" :items="installmentCount"
                  :error-messages="$v.form.pricing.priceOptions.$each[index].subscription.installmentCount.$error ||
                    ($v.form.pricing.priceOptions.$each[index].subscription.installmentCount.$dirty
                      && !option.subscription.installmentCount && !option.instant.amount)
                    ? $t('validation.required') : ''"
                  :label="$t('project.payments.pricingPriceOptionsSubscriptionInstallmentCount')"
                  outlined dense @blur="$v.form.pricing.priceOptions.$each[index].subscription.installmentCount.$touch()"
                />
              </v-col>
              <v-col v-if="option.subscription.installmentCount > 1" cols="1" />
              <v-col v-if="option.subscription.installmentCount > 1" cols="11" sm="5" class="py-1 px-3">
                <v-select
                  v-model="option.subscription.intervalCount" :items="intervalCount"
                  :error-messages="$v.form.pricing.priceOptions.$each[index].subscription.intervalCount.$error ||
                    ($v.form.pricing.priceOptions.$each[index].subscription.intervalCount.$dirty
                      && !option.subscription.intervalCount && !option.instant.amount)
                    ? $t('validation.required') : ''"
                  :label="$t('project.payments.pricingPriceOptionsSubscriptionIntervalCount.label')"
                  outlined dense @blur="$v.form.pricing.priceOptions.$each[index].subscription.intervalCount.$touch()"
                />
              </v-col>
              <v-col v-if="index === form.pricing.priceOptions.length - 1" cols="12">
                <v-btn class="w-auto" icon small color="primary" @click="addNewPriceOption()">
                  <v-icon dark class="mx-1">
                    add_box
                  </v-icon>
                  <span>{{ $t('project.payments.addPayment') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <confirm-save-dialog
      v-if="nextRoute && open"
      @close-dialog="open = false"
      @navigate="navigate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, requiredIf, numeric } from 'vuelidate/lib/validators'
import cc from 'currency-codes'
import { DateTime } from 'luxon'
import saveDialogUtils from '@/utils/mixins/saveDialog'
import formUtils from '@/utils/mixins/formUtils'
import { getUniqueId } from '@/utils/formatters'

const stripeAccountFormatCheck = val => val?.startsWith('acct_') && val?.length === 21

export default {
  name: 'Payments',
  components: {
    ConfirmSaveDialog: () => import('@/components/ConfirmSaveDialog'),
    DateField: () => import('@/components/formFields/DateField'),
    GlobalTooltip: () => import('@/components/GlobalTooltip'),
  },
  mixins: [formUtils, saveDialogUtils],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      form: {},
      isProrationEnabled: false,
      timezone: null,
      currencies: cc.data,
      stripeAccount: null,
      stripeAccountNotExist: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ approvalRequired: 'project/isSubscriberApprovalRequired' }),
    headerPayments() {
      return this.approvalRequired ? this.$t('project.payments.headerPaymentsAfter') : this.$t('project.payments.headerPayments')
    },
    installmentCount: () => [...Array(9)].map((_, i) => i + 1),
    intervalCount() {
      return [
        { text: this.$t('project.payments.pricingPriceOptionsSubscriptionIntervalCount.monthly'), value: 1 },
        { text: this.$t('project.payments.pricingPriceOptionsSubscriptionIntervalCount.quarterly'), value: 3 },
        { text: this.$t('project.payments.pricingPriceOptionsSubscriptionIntervalCount.biannual'), value: 6 },
      ]
    },
  },
  validations() {
    return {
      form: {
        pricing: {
          currency: { required },
          enrollPrice: { numeric },
          prorationDate: {
            start: { required: requiredIf(() => this.isProrationEnabled) },
            end: { required: requiredIf(() => this.isProrationEnabled) },
          },
          priceOptions: {
            $each: {
              name: { required },
              instant: { amount: { numeric } },
              subscription: {
                amount: {
                  numeric,
                  isValid: (value, subscription) => (Object.values(subscription).some(v => v)) ? !!(value) : true,
                },
                startBillingDate: {
                  isValid: (value, subscription) => (Object.values(subscription).some(v => v)) ? !!(value) : true,
                },
                installmentCount: {
                  isValid: (value, subscription) => (Object.values(subscription).some(v => v)) ? !!(value) : true,
                },
                intervalCount: {
                  isValid: (value, subscription) => {
                    if (subscription.installmentCount > 1
                        && (subscription.amount || subscription.startBillingDate || subscription.installmentCount)) {
                      return !!(value)
                    }
                    return true
                  },
                },
              },
            },
          },
        },
        stripe: {
          accountId: { required, stripeAccountFormatCheck },
        },
      },
      isProrationEnabled: {},
    }
  },
  created() {
    this.form = {
      pricing: {
        ...this.project?.pricing ?? {},
        currency: this.project?.pricing?.currency,
        enrollPrice: this.project?.pricing?.enrollPrice ?? null,
        prorationDate: {
          start: this.project?.pricing?.prorationDate?.start ?? null,
          end: this.project?.pricing?.prorationDate?.end ?? null,
        },
        priceOptions: this.project?.pricing?.priceOptions?.map(option => ({
          ...option,
          instant: { amount: option.instant?.amount ?? null },
          subscription: {
            amount: option.subscription?.amount ?? null,
            startBillingDate: option.subscription?.startBillingDate ?? null,
            installmentCount: option.subscription?.installmentCount ?? null,
            intervalCount: option.subscription?.intervalCount ?? null,
          },
        })) ?? [],
      },
      stripe: {
        ...this.project?.stripe ?? {},
        accountId: this.project?.stripe?.accountId,
      },
    }
    // change this var to isProrationEnabled
    this.isProrationEnabled = !!(this.form.pricing?.prorationDate?.start)
    this.timezone = this.project?.timezone
    // stripe connected account input
    this.isInputEnabled = !(this.form.stripe.accountId)
    this.readStripeAccountInfo()
  },
  methods: {
    addNewPriceOption() {
      this.form.pricing.priceOptions.push({
        id: getUniqueId(),
        name: null,
        instant: { amount: null },
        subscription: { amount: null, startBillingDate: null, installmentCount: null, intervalCount: null },
      })
    },
    isDirtyPriceOption: option => option.instant.amount.$dirty && Object.values(option.subscription).some(v => v.$dirty),
    isEmptyPriceOption: option => !option.instant.amount && Object.values(option.subscription).every(v => !v),
    parseForm() {
      return {
        ...this.form,
        pricing: {
          ...this.form.pricing,
          enrollPrice: this.form?.pricing?.enrollPrice ? this.form.pricing.enrollPrice : null,
          prorationDate: this.form.pricing.prorationDate?.start ? {
            start: DateTime.fromISO(`${this.form.pricing.prorationDate.start}T00:00:00`, { zone: this.timezone }).toJSDate(),
            end: DateTime.fromISO(`${this.form.pricing.prorationDate.end}T00:00:00`, { zone: this.timezone }).toJSDate(),
          } : null,
          priceOptions: this.form.pricing.priceOptions ? this.form.pricing.priceOptions.map(option => ({
            ...option,
            instant: option.instant.amount ? { ...option.instant, isProrated: false } : null,
            subscription: option.subscription.amount
              ? {
                ...option.subscription,
                interval: 'month',
                isProrated: this.isProrationEnabled,
                startBillingDate: DateTime.fromISO(`${option.subscription.startBillingDate}T00:00:00`, { zone: this.timezone }).toJSDate(),
                intervalCount: option.subscription.installmentCount > 1 ? option.subscription.intervalCount : null,
              } : null,
          })) : null,
        },
      }
    },
    update() {
      const priceOptionsSubscriptionEmpty = this.form.pricing.priceOptions.filter(o => this.isEmptyPriceOption(o))
      if (!this.isFormValid() || priceOptionsSubscriptionEmpty.length) {
        this.scrollToFirstError()
        return
      }

      const { organizationId, projectId } = this
      const data = this.parseForm()

      this.runAsync(() => this.$store.dispatch('project/updatePaymentsConfig', { organizationId, projectId, data }))
      this.$v.$reset()
    },
    goToStripe(accountId) {
      this.runAsync(async () => {
        const onboardingLink = await this.$store.dispatch('project/getStripeAccountOnboardingLink', { accountId })
        window.open(onboardingLink)
      })
    },
    createStripeAccount() {
      const { organizationId } = this
      this.runAsync(async () => {
        const account = await this.$store.dispatch('project/createStripeAccount', { organizationId })
        this.goToStripe(account.id)
      })
    },
    async readStripeAccountInfo() {
      this.$store.commit('loader/show')
      this.stripeAccount = await this.$store.dispatch('project/readStripeAccountInfo', { accountId: this.form.stripe.accountId })
      if (!this.stripeAccount) this.stripeAccountNotExist = true
      this.$store.commit('loader/hide')
    },
    getStripeAccountStatusColor: status => ({
      incomplete: 'error',
      paymentsDisabled: 'error',
      payoutsDisabled: 'orange',
      complete: 'success',
    }[status]),
    checkStripeAccount() {
      this.stripeAccount = null
      this.stripeAccountNotExist = false
      this.$v.form.stripe.accountId.$touch()
      if (!this.$v.form.stripe.accountId.$invalid) this.readStripeAccountInfo()
    },
  },
}
</script>

<style scoped lang="scss">
.v-input--selection-controls {
  margin: 0 !important;
  padding: 0 !important;
}

.lock-button {
  pointer-events: auto;
}
</style>
